



































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import FlowsList from 'piramis-base-components/src/components/FlowsList/FlowsList.vue'
import { FlowsListConfig } from 'piramis-base-components/src/components/FlowsList/types'
import { FlowsService } from '@/includes/services/FlowService'
import PostApi from 'piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi'
import BaseApi from '@/includes/services/BaseApi'
import FlowsListHint from "@/components/HelpMessages/FlowsListHint.vue"
import moment from 'moment'
import { errorNotificationWrapper } from 'piramis-base-components/src/logic/helpers/NotificationService'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import GettingTestPeriodPrime from '@/components/Boards/GettingTestPeriodPrime.vue'
import { hasChangeConfigPermission } from '@/includes/PermissionHelper'
import { instanceToPlain } from 'class-transformer'
import { errorNotification } from '@/includes/services/NotificationService'

@Component({
  components: {
    FlowsList,
    GettingTestPeriodPrime
  },
  data() {
    return {
      FlowsListHint,
      moment,
      hasChangeConfigPermission
    }
  }
})
export default class NewFlowsList extends Vue {
  config: FlowsListConfig | null = null

  isLoading = false

  goToFlow(flow_key: string, query?: Record<string, string> | undefined): void {
    this.$router.push({
      name: 'New_Flow',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ],
        actionType: 'edit'
      },
      query: {
        flow_key,
        ...query
      }
    })
  }

  created(): void {
    this.config = {
      getPreview: (key) => `https://api.presscode.app/thumbnail/flow/${ key }`,
      removeFlow: (flow_key) => this.$store.dispatch('removeFlow', flow_key),
      boardAvatar: this.$store.state.boardsState.activeBoard!.avatar,
      goToFlow: (key) => this.goToFlow(key),
      copyFlow: (flow_key, flow_title) => {
        const board_key = this.$store.state.boardsState.activeBoard!.board

        return FlowsService.newGetFlow('tg', {
          flow_key,
          board_key
        })
          .then(data => {
            FlowsService.createFlow('tg', {
              board_key,
              title: flow_title,
              scheme: data.plain.data.scheme,
            })
              .then((res) => {
                this.goToFlow(res.key)
              })
              .catch(errorNotification)
          })
          .catch(errorNotification)
      },
      getFlows: () => this.$store.dispatch('requestFlows'),
      createUtmLink: (utm, flow_key) => new Promise(resolve => {
        FlowsService.createFlowLink('tg', { board_key: this.$store.state.boardsState.activeBoard!.board, utm, flow_key })
          .then(({ link }) => resolve(link))
      }),
      exportFlowLink: (key) => `${ PostApi.getApiUrl() }tg/ExportFlow?board_key=${ this.$store.state.boardsState.activeBoard!.board }&flow_key=${ key }`,
      importFlow: (title, file) => {
        this.isLoading = true
        const FD = new FormData()

        FD.append('board_key', this.$store.state.boardsState.activeBoard!.board)
        FD.append('config', file)
        FD.append('title', title)

        return BaseApi.sendRequest('tg', 'ImportFlow', FD)
          .then(({ key }) => {
            this.$store.dispatch('getBoardConfig', this.$store.state.boardsState.activeBoard!.board)
            this.goToFlow(key)
          })
          .catch((e) => {
            this.isLoading = false
            errorNotificationWrapper(this.$t('error').toString(), e)
          })
          .finally(() => this.isLoading = false)
      },
      createFlow: (title, scheme) => {
        const query = !this.$store.state.flowsState.flows?.length ? {
          onboarding: 'true',
        } : undefined

        return this.$store.dispatch('createFlow', { title, scheme })
          .then(({ key }) => this.goToFlow(key, query))
      },
      importFlowTemplate: {
        onComplete: async (data) => {
          await this.$store.dispatch('createFlowTemplate', {
            board_key: this.$store.state.boardsState.activeBoard!.board,
            ...data.template
          })
        },
        targetOptions: () => {
          return this.$store.getters.boardTargetOptions
        }
      },
      onFlowMastersButtonClick: () => {
        this.$router.push({ name: 'FlowMasters' })
      }
    }
  }
}




















import Vue from 'vue'
import { Component } from "vue-property-decorator"
import FlowMainHint from './FlowMainHint.vue'

@Component({
  components: {
    FlowMainHint,
  }
})
export default class FlowHintComponent extends Vue {
  isOpen = false
}





































import Vue from 'vue'
import { Component } from "vue-property-decorator"
import FlowHintComponent from '@/components/HelpMessages/flow/flowHintComponent.vue'

@Component({
  components: {
    FlowHintComponent,
  }
})
export default class FlowsListHint extends Vue {
  allPossibilities = false

  moreAboutCreating = false
}
